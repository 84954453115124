import videojs from 'video.js';
import youtube from 'videojs-youtube';
import 'tw-elements';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

(function() {
  var hamburger = {
	navToggle: document.querySelector('#navToggle'),
	nav: document.querySelector('#nav-mobile'),
	doToggle: function(e) {
//	  e.preventDefault();
	  this.navToggle.classList.toggle('is-active');
	  this.nav.classList.toggle('active');
	}
  };
  hamburger.navToggle.addEventListener('click', function(e) { hamburger.doToggle(e); });
  hamburger.nav.addEventListener('click', function(e) { hamburger.doToggle(e); });
}());